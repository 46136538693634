import React, { FunctionComponent, useEffect, useState } from "react";
import { ApolloError, useQuery } from '@apollo/client';
import { Typography, Button, IconButton } from "@material-ui/core";
import { Link } from "gatsby";
import { FaSpotify, FaYoutube, FaChevronRight, FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";
import { SiTiktok } from "react-icons/si";
import { Carousel } from "react-responsive-carousel";
import parse from "html-react-parser";
import "react-responsive-carousel/lib/styles/carousel.css";

import { GET_HOMEPAGE } from '../graphql/posts/get-home-page';
import { GET_CAROUSEL_POSTS } from '../graphql/posts/get-carousel-posts';
import { GET_RECENT_POSTS } from '../graphql/posts/get-recent-posts';

import SEO from "../components/seo/seo";
import Loading from "../components/loading/loading";
import GeneralPostCard from "../components/cards/general-post/general-post-card";
import IndexStyle from "../styles/index-style";
// @ts-ignore
import * as logo from '../images/jm_logo.svg';


const IndexPage: FunctionComponent = () => {
  const [ homePage, setHomePage ] = useState<any>([]);
  const [ carouselPosts, setCarouselPosts ] = useState<any>([]);
  const [ recentPosts, setRecentPosts ] = useState<any>([]);
  const { loading: loadingHomePage, data: homePageData } = useQuery(GET_HOMEPAGE, {
    fetchPolicy: 'no-cache',
    onError: (e: ApolloError) => {
      console.log('Home Page Error: ', e);
    }
  });
  const { loading: loadingCarouselPosts, data: carouselPostData } = useQuery(GET_CAROUSEL_POSTS, {
    fetchPolicy: 'no-cache',
    onError: (e: ApolloError) => {
      console.log('Carousel Posts Error: ', e);
    }
  });
  const { loading: loadingRecentPosts, data: recentPostData } = useQuery(GET_RECENT_POSTS, {
    fetchPolicy: 'no-cache',
    onError: (e: ApolloError) => {
      console.log('Recent Posts Error: ', e);
    }
  });

  useEffect(() => {
    if (homePageData) setHomePage(homePageData.pages.nodes[0].homeACF);
    if (carouselPostData) {
      const carousel = carouselPostData.posts.edges.filter(
        (post) => post.node.Posts.postType !== "Podcast",
      );
      setCarouselPosts(carousel);
    }
    if (recentPostData) {
      const recent = recentPostData.posts.edges.filter(
        (blog) => blog.node.Posts.postType !== "Podcast",
      );
      setRecentPosts(recent)
    };
  }, [carouselPostData, recentPostData, homePageData]);

  const CarouselPanels = [];
  for (const [index, value] of carouselPosts.entries()) {
    CarouselPanels.push(
      <Link
        to={`/${value.node.Posts.postType.toLowerCase()}/post/?id=${value.node.databaseId}`}
        state={{ id: value.node.databaseId }}
        key={ index }
        title={ value.node.featuredImage.node.altText }
      >
        <div className="carousel-panel background-image" style={{ backgroundImage: `url(${value.node.featuredImage.node.sourceUrl})` }} >
          {/* <Typography gutterBottom variant="h2" component="h2" className="title">
            {value.node.title}
          </Typography> */}
        </div>
      </Link>
    )
  };

  const GeneralPostCards = [];
  for (const [index, value] of recentPosts.slice(0, 8).entries()) {
    GeneralPostCards.push(
      <div key={index} className="col-12 col-sm-6 col-lg-3 mb-4">
        <GeneralPostCard post={value.node} />
      </div>
    )
  };

  const goToLink = (url: string, target?: string): void => {
    window.open(url, target);
  }

  return (
    <IndexStyle>
      <SEO
        title="Welcome To Joystick Maverick"
      />
      <div className="logo-container text-center">
        <img className="index-logo" src={logo} alt="Joystick Maverick" height="72" />
      </div>
      <div className="container-fluid px-0">
        {loadingCarouselPosts ? (
          <Loading />
        ) : (
          <>
            { CarouselPanels.length > 0 ? (
              <Carousel
                showArrows
                infiniteLoop
                useKeyboardArrows
                stopOnHover={false}
                showStatus={false}
                showThumbs={false}
                interval={7500}
                transitionTime={500}
                centerMode
                centerSlidePercentage={75}
              >
                { CarouselPanels }
              </Carousel>
            ) : (
              <Typography variant="body1" gutterBottom>No posts.</Typography>
            )}
          </>
        )}
      </div>
      {loadingHomePage ? (
        <Loading />
      ) : (
        <>
          <div className="container my-2 py-2 my-md-4 py-md-4 my-lg-5 py-lg-5">
            <div className="row align-items-top">
              <div className="col-12 col-lg-6 mb-3">
                <Typography variant="h3" gutterBottom>{ homePage.videoHeader }</Typography>
                <div className="mt-5">
                  {parse(`${homePage.videoContent}`)}
                </div>
                <Button className="my-5" variant="contained" color="primary" onClick={() => goToLink(homePage.videoLink?.url, homePage.videoLink?.target)}>{ homePage.videoLink?.title }<FaYoutube className="ml-2" /></Button>
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <div className="w-100 background-image box-shadow" style={{ backgroundImage: `url(${homePage.videoImage?.sourceUrl})` }} title={homePage.videoImage?.altText} />
              </div>
            </div>
          </div>
          <div className="container my-2 py-2 my-md-4 py-md-4 my-lg-5 py-lg-5">
            <div className="row align-items-top">
              <div className="order-12 order-lg-1 col-12 col-lg-6 mb-3">
                <div className="w-100 background-image box-shadow" style={{ backgroundImage: `url(${homePage.podcastImage?.sourceUrl})` }} title={homePage.podcastImage?.altText} />
              </div>
              <div className="order-1 order-lg-12 col-12 col-lg-6 mb-3">
                <Typography variant="h3" gutterBottom>{ homePage.podcastHeader }</Typography>
                <div className="mt-5">
                  {parse(`${homePage.podcastContent}`)}
                </div>
                <Button className="my-5" variant="contained" color="primary" onClick={() => goToLink(homePage.podcastLink?.url, homePage.podcastLink?.target)}>{ homePage.podcastLink?.title }<FaSpotify className="ml-2" /></Button>
              </div>
            </div>
          </div>
          <div className="container my-2 py-2 my-md-4 py-md-4 my-lg-5 py-lg-5">
          <div className="row align-items-center">
              <div className="col">
                <Typography variant="h3" gutterBottom>{ homePage.socialHeader }</Typography>
                <div className="mt-5">
                  {parse(`${homePage.socialContent}`)}
                </div>
              </div>
              <div className="col-auto">
                <IconButton className="my-2 d-block" onClick={() => goToLink(homePage.facebookLink?.url, homePage.facebookLink?.target)}><FaFacebook size="2rem" /></IconButton>
                <IconButton className="my-2 d-block" onClick={() => goToLink(homePage.twitterLink?.url, homePage.twitterLink?.target)}><FaTwitter size="2rem" /></IconButton>
                <IconButton className="my-2 d-block" onClick={() => goToLink(homePage.instagramLink?.url, homePage.instagramLink?.target)}><FaInstagram size="2rem" /></IconButton>
                <IconButton className="my-2 d-block" onClick={() => goToLink(homePage.tiktokLink?.url, homePage.tiktokLink?.target)}><SiTiktok size="2rem" /></IconButton>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="container-fluid content py-5">
        <div className="row align-items-center">
          <div className="col">
            <Typography variant="h3" gutterBottom>Recent Posts</Typography>
            <Link to="/all" className="pl-2" target="_blank">View All <FaChevronRight className="ml-2" /></Link>
          </div>
        </div>
        {loadingRecentPosts ? (
          <Loading />
        ) : (
          <>
            <div className="my-5 py-3">
              <div className="row">
                <div className="col">
                  <div className="row align-items-top">
                    { GeneralPostCards.length > 0 ? (
                      GeneralPostCards
                    ) : (
                      <Typography variant="body1" gutterBottom>No posts.</Typography>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center text-center">
              <div className="col">
                <Button variant="contained" color="primary" onClick={() => goToLink('/all', '_self')}>View All</Button>
              </div>
            </div>
          </>
        )}
      </div>
    </IndexStyle>
  );
}

export default IndexPage
