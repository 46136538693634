import { gql } from "@apollo/client";

export const GET_HOMEPAGE = gql`
  query GetHomePage {
    pages(where: {title: "Home"}) {
      nodes {
        id
        homeACF {
          facebookLink {
            target
            title
            url
          }
          instagramLink {
            target
            title
            url
          }
          podcastContent
          podcastHeader
          podcastImage {
            altText
            sourceUrl
          }
          podcastLink {
            target
            title
            url
          }
          socialContent
          socialHeader
          socialImage {
            altText
            sourceUrl
          }
          videoHeader
          videoContent
          videoLink {
            url
            title
            target
          }
          videoImage {
            altText
            sourceUrl
          }
          twitterLink {
            url
            title
            target
          }
          tiktokLink {
            url
            title
            target
          }
        }
      }
    }
  }
`;

export default GET_HOMEPAGE;