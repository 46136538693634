import { gql } from "@apollo/client";

export const GET_CAROUSEL_POSTS = gql`
  query GetSliderPosts {
    posts(first: 5) {
      edges {
        node {
          Posts {
            postType
          }
          author {
            node {
              name
            }
          }
          categories {
            nodes {
              id
              name
            }
          }
          featuredImage {
            node {
              id
              altText
              sourceUrl
              title
            }
          }
          date
          title
          databaseId
        }
      }
    }
  }
`;

export default GET_CAROUSEL_POSTS;