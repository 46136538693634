import styled from 'styled-components'
// @ts-ignore
import * as bg from '../images/2021_05_JM_Blurred_Arcade.svg';

const IndexStyle = styled.div`

  .carousel-panel {
    margin: 2rem 1rem;
    padding: 2rem 2rem 2rem 0;
    transition: transform 0.15s ease;
    border: 1px solid var(--fadedWhite);
    border-radius: 1rem;
    text-align: left;
    color: white;
    position:relative;

    &:hover {
      transform: scale(1.025);
    }

    .title {
      background: var(--primary);
      padding: 0.25rem 0.25rem 0.25rem 2.25rem;
      display: inline-block;
      position:absolute;
      left: 0rem;
      right: 2rem;
      bottom: 2rem;
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .background-image {
    padding-top: 56.25%;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
  }

  .carousel {
    padding-bottom: 2rem;
    .control-dots {
      bottom: 0rem;
      right: 2rem;
      width: initial;
    }
  }

  p {
    font-size: 1.125rem;
    line-height: 2rem;
  }

  .carousel-root {
    margin-top: 1rem;
  }

  .logo-container {
    background-color: var(--darkBlue);
    background-image: url(${bg});
    background-repeat: no-repeat;
    background-position: right top;
    background-size: auto 150%;
    padding: 4.5rem 0;

    img.index-logo {
      height: 6rem;
      width: auto;
    }
  }

  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) { 
    .carousel-root {
      margin-top: 0.5rem;
    }
    .carousel-panel {
      margin: 0 0.25rem 1rem;
      padding-bottom: 0;
      border-radius: 0;
      border: none;

      .title {
        font-size: 1rem;
        padding-left: 1rem;
      }
    }

    .logo-container {
      padding: 3rem 0;

      img.index-logo {
        height: 3rem;
        width: auto;
      }
    }

    h3 {
      font-size: 2rem;
    }

    p {
      font-size: 1rem;
    }

    .carousel {
      .control-dots {
        bottom: 0rem;
        right: 1rem;
        .dot {
          width: 0.4rem;
          height: 0.4rem;
        }
      }
    }
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) and (max-width: 767.98px) {
    .carousel-panel {
      .title {
        font-size: 1.5rem;
        padding-left: 1rem;
      }
    }

    .logo-container img.index-logo {
      height: 4rem;
      width: auto;
    }
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) and (max-width: 991.98px) {
    .carousel-panel {
      .title {
        font-size: 2rem;
        padding-left: 1rem;
      }
    }
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .carousel-panel {
      .title {
        font-size: 3rem;
        padding-left: 1.75rem;
      }
    }
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    .carousel-panel {
      .title {
        font-size: 3.75rem;
      }
    }
  }
`;

export default IndexStyle